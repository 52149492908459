import { useLayoutEffect } from "react";
import { n5BlogUrl } from "@utils/constants"
import "@styles/index.scss";
import { useI18next } from "gatsby-plugin-react-i18next";


const Article = ({ uri }) => {

  const { language } = useI18next();
  const lang = language === "pr" ? "pt-br" : language;

  useLayoutEffect(() => {
    var newUri = uri.substring(uri.lastIndexOf('/'), uri.length);
    var url = n5BlogUrl + lang + newUri + '/';
    window.location.assign(url);
  }, []);

  return null;
};

export default Article;
